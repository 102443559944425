import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { Redirect, useLocation } from 'react-router-dom';

import { AppHeader } from '../Header';
import { AppFooter } from '../Footer';

import { Layout, Spin } from 'antd';

const { Content } = Layout;

export const ProtectedRoute = (props) => {
    const location = useLocation();
    const [authFailed, setAuthFailed] = useState(false);
    const [trialExpired, setTrialExpired] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const checkToken = async () => {
            const url = `${props.apiUrl}/merchant/verify/`;
            try {
                const response = await axios.get(url);
                console.log("response",response.data)
                if (response.data.trialExpired &&
                    location.pathname !== "/payments/billing") {
                    setTrialExpired(true);
                }

                setAuthFailed(false);
                setLoading(false);
            } catch (e) {
                setAuthFailed(true);
                setLoading(false);
            }
        }
        const tokenIsSet = props.Cookies.get("token");
        if (tokenIsSet) checkToken(tokenIsSet)
        else {
            setAuthFailed(true);
            setLoading(false);
        }
    }, []);

    const Component = props.component;
    const currentPage = location.pathname === '/' ? '/dashboard' : location.pathname;

    if (loading) return (
        <div style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Spin style={{
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
            }} size={'large'} />
        </div>
    );

    if (authFailed) {
        window.location.href =  '/login';
        return;
    } else if (trialExpired) {
        window.location.href =  '/payments/billing?trialExpired=1';
        return;
    } else {
        return (
            <Layout className="layout">
                <AppHeader currentPage={currentPage} />
                <Content style={{ padding: '0 50px' }}>
                    <div style={{ margin: '30px 0' }}></div>
                    <div className="site-layout-content">
                        <Component apiUrl={props.apiUrl} Cookies={props.Cookies} />
                    </div>
                </Content>
                <AppFooter />
            </Layout>
        )
    }
};
