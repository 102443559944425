function getStatusTag(status, Tag) {
    const formatting = {};

    switch (status) {
        case 'delivered': formatting.color = 'green'; formatting.label = 'Delivered'; break;
        case 'in review': formatting.color = 'gold'; formatting.label = 'In Review'; break;
        case 'scheduled': formatting.color = 'blue'; formatting.label = 'Scheduled'; break;
        default: break;
    }

    return formatting;
}

module.exports = { getStatusTag };