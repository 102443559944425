import React, { useEffect, useState } from 'react';

import {
    Table,
    Tag,
    Space,
    Menu,
    Dropdown,
    Button,
    PageHeader,
    Skeleton,
   
} from 'antd';
import Alert from "@mui/material/Alert";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import moment from 'moment';
import axios from 'axios';

import { getStatusTag } from '../../utils';

export const Campaigns = (props) => {
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [approxChargePerCampaigns,setApproxChargePerCampaigns] = useState(0);
    const [balance, setBalance] = useState(0);
    const [isShowWallet , setIsShowWallet] = useState(false);
    useEffect(() => {
        const fetchCampaigns = async () => {
            const url = `${props.apiUrl}/campaign/list/`;
            const response = await axios.get(url);

            setCampaigns(response.data.campaigns);
            setLoading(false);
        }

        const fetchSubscribers = async () => {
            // const perTextMessageCharge = 0.0079;
            const perPictureMessageCharge = 0.0200;
            const url = `${props.apiUrl}/subscriber/list/`;
            const response = await axios.get(url);
            const totalSubscriber = response.data.subscribers.length
            const TotalApporxCharge = (perPictureMessageCharge)*totalSubscriber
            setApproxChargePerCampaigns(TotalApporxCharge)
          };

          const fetchTransactionData = async () => {
            const url = `${props.apiUrl}/wallet/transaction`;
            const response = await axios.get(url);
            const data = response.data;
            if(data.length > 0){
                setBalance(data[0].amount)
                setIsShowWallet(true)
        
            }
        }
        fetchCampaigns();
        fetchSubscribers();
        fetchTransactionData()
    }, []);


    const menu = (
        <Menu onClick={() => { }}>
            <Menu.Item key="1">Delete</Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: 'Campaign',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => <Link to={`/campaigns/${record._id}`}>{text}</Link>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => {
                const formatting = getStatusTag(status);
                return <Tag color={formatting.color}>{formatting.label}</Tag>
            }
        },
        {
            title: 'Recepients',
            key: 'recepients',
            dataIndex: 'recepients',
            render: recipients => recipients.length
        },
        {
            title: 'Campaign Date',
            key: 'date',
            dataIndex: 'date',
            render: date => moment(date).format("MMM DD, YYYY")
        },
        {
            title: 'Units Sold',
            key: 'orders',
            dataIndex: 'performance',
            render: performance => performance.sold
        },
        {
            title: 'Revenue Earned',
            key: 'revenue',
            dataIndex: 'performance',
            render: performance => `$${performance.revenue.toFixed(2)}`
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space size="middle">
                    <Dropdown.Button overlay={menu}>
                        <Link to={`/campaigns/${record._id}`}>View</Link>
                    </Dropdown.Button>
                </Space>
            ),
        },
    ];

    return (
        <>
            <Helmet>
                <title>Campaigns - TextAnOffer</title>
            </Helmet>

            { isShowWallet &&  balance === 0 ?  <Alert severity="error">
                Your Wallet is Runing With Insufficient Balance  ${balance.toFixed(2)} .Please Recharge Now !
            </Alert> :  isShowWallet && balance < 5  ?  <Alert severity='warning'>
                Your Wallet is Runing With Insufficient Balance  ${balance.toFixed(2)} .`
            </Alert>  : null
          
          }
        <br></br>
                
            <Alert severity="info" >Send the Campaign/Text Message ${approxChargePerCampaigns.toFixed(2)} Amount will Be Charge </Alert>

           <PageHeader
                title={"Campaigns"}
                subTitle={"Here is a list of your campaigns"}
                extra={[
                    <Button type={"primary"} style={{ float: "right" }}>
                        <Link to={"/campaigns/create"}>
                            Create Campaign
                        </Link>
                    </Button>
                ]}
            />
                
            {!loading ?
                <Table columns={columns} dataSource={campaigns} />
                :
                <Skeleton />
            }
        </>
    )
}
