import React, { useState } from "react";

// import { Chart, Line, Point, Tooltip, Axis } from "bizcharts";
import Chart from 'react-apexcharts'
import moment from 'moment';
import { Radio } from 'antd';
import { AppHeader } from '../components/Header';
import { Grid, Typography } from "@mui/material";
const today = new Date();
const today1 = new Date(today);

today.setHours(0, 0, 0, 0);

today1.setHours(0, 0, 0, 0);
today1.setDate(today1.getDate() + 1);

function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date);
    newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return newDate;
}

const getDaysDifference = (start, end) => {
    const time = end.getTime() - start.getTime();
    return time / (1000 * 3600 * 24);
}

export const DashboardChart = (props) => {
    const daysInBetween = Math.ceil(getDaysDifference(props.startDate, props.endDate));
    const [metric, setMetric] = useState('revenue');
    const days = Array.from(Array(daysInBetween).keys());

    const data = {
        orders: days.map((x, i) => {
            const dateToAdd = new Date(props.startDate);
            dateToAdd.setDate(dateToAdd.getDate() + i);

            const filteredOrders = props.orders.filter(order => {
                const orderDate = convertUTCDateToLocalDate(new Date(order.date));
                return orderDate.getDate() === dateToAdd.getDate();
            });

            const rev = filteredOrders.reduce((prev, next) => prev + parseFloat(next.total), 0);

            return {
                keyword: "Revenue",
                dates: dateToAdd,
                first: `$${rev.toFixed(2)}`
            }
        }),
        subscribers: days.map((x, i) => {
            const dateToAdd = new Date(props.startDate);
            dateToAdd.setDate(dateToAdd.getDate() + i);

            const filteredSubscribers = props.subscribers.filter(subscriber => {
                const subscriberDate = convertUTCDateToLocalDate(new Date(subscriber.createdAt));
                return subscriberDate.getDate() === dateToAdd.getDate();
            });

            return {
                keyword: "Subscribers",
                dates: dateToAdd,
                first: filteredSubscribers.length
            }
        }),
        messages: days.map((x, i) => {
            const dateToAdd = new Date(props.startDate);
            dateToAdd.setDate(dateToAdd.getDate() + i);

            const filteredMessages = props.messages.filter(message => {
                const messageDate = convertUTCDateToLocalDate(new Date(message.createdAt));
                return messageDate.getDate() === dateToAdd.getDate();
            });

            return {
                keyword: "Messages",
                dates: dateToAdd,
                first: filteredMessages.length
            }
        })
    };
    const cols = {
        dates: {
            range: [0.05, 0.95],
            type: 'timeCat'
        }
    };
    const renderChart = (data) => {
      var dates = [];
        var first =[];
        for(var i=0 ; i<data.length; i++){
            dates.push(moment(data[i].dates).format("MMM Do, YYYY"));
            first.push(data[i].first);
        }

        
        const cols = {
            dates: {
                range: [0.05, 0.95],
                type: 'timeCat'
            }
        };

        return (
            <Chart options={{
                chart: {
                  id: metric
                },
                xaxis: {
                  categories: dates
                }
              }} series={[{
                name: metric,
                data: first
              }]} type="line" width={1150} height={400} />
            //     <Chart
            //     padding={[10, 20, 50, 50]}
            //     autoFit
            //     height={500}
            //     data={data}
            //     scale={cols}
            // >   
            //     <Line position="dates*first" />
            //     <Point position="dates*first" />
            //     <Tooltip showCrosshairs lock />
            //     <Axis name='dates' />
            //     <Axis name='first' />
            // </Chart> 
           
            
       
        )
    }

    return (
        <>
            <Radio.Group value={metric} onChange={e => setMetric(e.target.value)}>
                <Radio.Button value="revenue">Revenue</Radio.Button>
                <Radio.Button value="subscribers">Subscribers</Radio.Button>
                <Radio.Button value="messages">Messages</Radio.Button>
            </Radio.Group>
           
               {metric === 'messages' && renderChart(data.messages)}
                {metric === 'revenue' && renderChart(data?.orders)}
                {metric === 'subscribers' && renderChart(data.subscribers)}
             <Grid container alignItems="center" sx={{ mt: 1 }}>
              <Grid item xs>
                <Typography style={{'font-size': '9px'}} gutterBottom component="div">
                We hope you don't leave us but we understand if you do have to go. To cancel your subscription, please email us at
                <a> admin@textanoffer.com </a> 
                with 'Cancel' in the Subject line
                </Typography>
              </Grid>
            
            </Grid>
        </>
    )
}
