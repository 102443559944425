import React from 'react'
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';

const { Header } = Layout;

export const AppHeader = (props) => {
    return (
        <Header>
            <div className="logo">
                <img src={"/logo.svg"} alt={"Logo"} width={200} style={{ marginTop: '-18px' }} />
            </div>
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[`${props.currentPage}`]}>
                <Menu.Item key="/dashboard">
                    <a href='/dashboard'>Dashboard</a>
                </Menu.Item>
                <Menu.Item key="/subscribers">
                    <a href={'/subscribers'}>Subscribers</a>
                </Menu.Item>
                <Menu.Item key="/campaigns">
                    <a href={'/campaigns'}>Campaigns</a>
                </Menu.Item>
                <Menu.Item key="/orders">
                    <a href={'/orders'}>Orders</a>
                </Menu.Item>
                <Menu.Item key="/embed">
                    <a href={'/embed'}>Embed</a>
                </Menu.Item>
                <Menu.Item key="/wallet">
                    <a href={'/wallet'}>Wallet</a>
                </Menu.Item>
                <Menu.Item key="/logout">
                    <a href={'/logout'}>Logout</a>
                </Menu.Item>
            </Menu>
        </Header>
    )
}
