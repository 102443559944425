import React, { useState, useEffect } from 'react';

import { Link, useLocation } from "react-router-dom";

import axios from 'axios';
import moment from 'moment';

import { Col, PageHeader, Row, Tabs, Table, Card, Tag } from 'antd';
import { Helmet } from 'react-helmet';

const { TabPane } = Tabs;

export const CampaignView = (props) => {
    const location = useLocation();
    const [, , id] = location.pathname.split('/');
    const [campaign, setCampaign] = useState({});
    const [messages, setMessages] = useState([]);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchCampaignData = async () => {
            const url = `${props.apiUrl}/campaign/${id}`;
            const response = await axios.get(url);

            setCampaign(response.data.campaign);
            setMessages(response.data.messages);
            setOrders(response.data.orders);
            setLoading(false);
        }
        fetchCampaignData();
    }, []);


    function callback(key) {
        console.log(key);
    }

    const getOrderStatus = (status) => {
        let tag;
        switch (status) {
            case 'processing': tag = <Tag>Processing</Tag>; break;
            case 'processed': tag = <Tag color={"green"}>Processed</Tag>; break;
            case 'cancelled': tag = <Tag color={"red"}>Cancelled</Tag>; break;
            default: tag = null; break;
        }

        return tag;
    };

    const ordersColumns = [
        {
            title: 'Order',
            dataIndex: 'number',
            key: 'number',
            render: (d, s) => <Link to={`/orders/${s._id}`}>#{d}</Link>
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: d => moment(d).format("MMM DD, YYYY hh:mma")
        },
        {
            title: 'Subscriber',
            dataIndex: 'subscriberInfo',
            key: 'subscriberInfo',
            render: d => `${d.firstName} ${d.lastName}`
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: d => `$${d.toFixed(2)}`
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: d => getOrderStatus(d)
        },
    ];

    const messagesColumns = [
        {
            title: 'Subscriber',
            dataIndex: 'subscriberInfo',
            key: 'subscriberInfo',
            render: (d, s) => <Link to={`/subscribers/${s.subscriber}`}>{d.firstName} {d.lastName}</Link>
        },
        {
            title: 'Phone number',
            dataIndex: 'nationalFormat',
            key: 'nationalFormat'
        },
        {
            title: "Sent at",
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: d => moment(d).format("MMM Do YYYY, h:mm:ss a")
        },
        {
            title: "Opted out?",
            dataIndex: 'unsubscribed',
            key: 'unsubscribed',
            render: d => d ? "Yes" : "No"
        },
    ];

    const totalRevenue = orders.reduce((a, b) => a + b.total, 0).toFixed(2);
    const averageOrderValue = `$${(totalRevenue / (orders.length || 0)).toFixed(2)}`;
    const conversionRate = `${((orders.length || 0) / (messages.length || 0) * 100).toFixed(2)}%`;

    const messagesSent = messages.length;
    const unsubscribers = messages.reduce((a, b) => a + b.unsubscribed || 0, 0);
    const unsubscribedRate = `${(unsubscribers / (messages.length || 0) * 100).toFixed(2)}%`;

    return (
        !loading && <>
            <Helmet>
                <title>{campaign.title} - TextAnOffer</title>
            </Helmet>
            <PageHeader
                title={"Campaign Statistics"}
                subTitle={campaign.title}
                onBack={() => window.history.back()}
            />
            <Row>
                <Col span={24}>
                    <Tabs defaultActiveKey="1" onChange={callback}>
                        <TabPane tab="Orders" key="1">
                            <Row>
                                <Col lg={8} md={8} xs={24} sm={24}>
                                    <Card title="Total Revenue" style={{ marginTop: 8, marginRight: 30 }}>
                                        <h1>${totalRevenue}</h1>
                                    </Card>
                                </Col>
                                <Col lg={8} md={8} xs={24} sm={24}>
                                    <Card title="Average Order Value" style={{ marginTop: 8, marginRight: 30 }}>
                                        <h1>{averageOrderValue}</h1>
                                    </Card>
                                </Col>
                                <Col lg={8} md={8} xs={24} sm={24}>
                                    <Card title="Conversion Rate" style={{ marginTop: 8, marginRight: 30 }}>
                                        <h1>{conversionRate}</h1>
                                    </Card>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col span={24}>
                                    <Table
                                        columns={ordersColumns}
                                        dataSource={orders}
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Messages" key="2">
                            <Row>
                                <Col lg={8} md={8} xs={24} sm={24}>
                                    <Card title="Messages Sent" style={{ marginTop: 8, marginRight: 30 }}>
                                        <h1>{messagesSent}</h1>
                                    </Card>
                                </Col>
                                <Col lg={8} md={8} xs={24} sm={24}>
                                    <Card title="Unsubscribers" style={{ marginTop: 8, marginRight: 30 }}>
                                        <h1>{unsubscribers}</h1>
                                    </Card>
                                </Col>
                                <Col lg={8} md={8} xs={24} sm={24}>
                                    <Card title="Unsubscribe Rate" style={{ marginTop: 8, marginRight: 30 }}>
                                        <h1>{unsubscribedRate}</h1>
                                    </Card>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col span={24}>
                                    <Table columns={messagesColumns} dataSource={messages} />
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </>
    )
}
