import React, { useEffect, useState } from 'react';

import axios from 'axios';
import moment from 'moment';

import { Link } from 'react-router-dom';

import { PageHeader, Skeleton, Table, Tag ,Button ,Alert} from 'antd';

import { Helmet } from 'react-helmet';

export const Orders = (props) => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [balance, setBalance] = useState(0);
    const [isShowWallet , setIsShowWallet] = useState(false);
    useEffect(() => {
        const fetchOrders = async () => {

            const url = `${props.apiUrl}/order/list/`;
            const response = await axios.get(url);

            setOrders(response.data.orders);
            setLoading(false);
        }
        fetchOrders();
        fetchTransactionData();
    }, []);

    const getOrderStatus = (status) => {
        let tag;
        switch (status) {
            case 'processing': tag = <Tag>Processing</Tag>; break;
            case 'processed': tag = <Tag color={"green"}>Processed</Tag>; break;
            case 'cancelled': tag = <Tag color={"red"}>Cancelled</Tag>; break;
            default: tag = null; break;
        }

        return tag;
    };

    const fetchTransactionData = async () => {
        const url = `${props.apiUrl}/wallet/transaction`;
        const response = await axios.get(url);
        const data = response.data;
        if(data.length > 0){
            setBalance(data[0].amount)
            setIsShowWallet(true)
    
        }
    } 

    const ordersColumns = [
        {
            title: 'Order',
            dataIndex: 'number',
            key: 'number',
            render: (text, record) => <Link to={`/orders/${record._id}`}>#{text}</Link>
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: a => moment(a).format("MMM DD, YYYY hh:mma")
        },
        {
            title: 'Subscriber',
            dataIndex: 'subscriberInfo',
            key: 'subscriberInfo',
            render: a => `${a.firstName} ${a.lastName}`
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: a => `$${a.toFixed(2)}`
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: a => getOrderStatus(a)
        },
    ];

    return (
        <>
{ isShowWallet &&  balance === 0 ?  <Alert
                action={
                    <Button type={"link"}>
                        <Link to={'/wallet'}>Recharge Now</Link>
                    </Button>
                }
                message={ `Your Wallet is Runing With Insufficient Balance $ ${balance.toFixed(2)} .Please Recharge Now !`}
                type={ 'info'}
            /> :isShowWallet &&  balance < 5  ?  <Alert
            action={
                <Button type={"link"}>
                    <Link to={'/wallet'}>Recharge Now</Link>
                </Button>
            }
            message={ `Your Wallet is Runing With Insufficient Balance $ ${balance.toFixed(2)} .`}
            type={ 'warning'}
        />  : null
          
          }
        <Helmet>
            <title>Orders - TextAnOffer</title>
        </Helmet>
            <PageHeader title={"Orders"} subTitle={"Here is a list of your orders"} />
            {loading ?
                <Skeleton />
                :
                <Table columns={ordersColumns} dataSource={orders} />
            }
        </>
    )
}
