import React, { useState, useEffect } from 'react';

import { Link, useLocation } from "react-router-dom";

import axios from 'axios';
import moment from 'moment';

import {
    Col,
    PageHeader,
    Row,
    Input,
    Form,
    Table,
    Card,
    Tag,
    Skeleton
} from 'antd';

import { Helmet } from 'react-helmet';

export const SubscriberView = (props) => {
    const location = useLocation();
    const [, , id] = location.pathname.split('/');
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [addressOne, setAddressOne] = useState("");
    const [addressTwo, setAddressTwo] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [status, setStatus] = useState("");
    const [orders, setOrders] = useState([]);
    const [messages, setMessages] = useState(0);
    useEffect(() => {
        const fetchSubscriberData = async () => {
            const url = `${props.apiUrl}/subscriber/view/${id}/`;
            const response = await axios.get(url);

            setBusinessName(response.data.subscriber.businessName);
            setFirstName(response.data.subscriber.firstName);
            setLastName(response.data.subscriber.lastName);
            setPhoneNumber(response.data.subscriber.phoneNumber);
            setName(`${response.data.subscriber.firstName} ${response.data.subscriber.lastName}`);
            setAddressOne(response.data.subscriber.addressOne);
            setAddressTwo(response.data.subscriber.addressTwo);
            setStatus(response.data.subscriber.status);
            setCity(response.data.subscriber.city);
            setState(response.data.subscriber.state);
            setZipCode(response.data.subscriber.zipCode);
            setMessages(response.data.subscriber.messages);
            setOrders(response.data.orders);
            setLoading(false);
        };

        fetchSubscriberData();
    }, []);

    const getStatus = (status) => {
        let tag;
        switch (status) {
            case 'subscribed': tag = <Tag color={"green"}>Subscribed</Tag>; break;
            case 'unsubscribed': tag = <Tag color={"red"}>Unsubscribed</Tag>; break;
            default: tag = null; break;
        }

        return tag;
    };

    const getOrderStatus = (status) => {
        let tag;
        switch (status) {
            case 'processing': tag = <Tag>Processing</Tag>; break;
            case 'processed': tag = <Tag color={"green"}>Processed</Tag>; break;
            case 'cancelled': tag = <Tag color={"red"}>Cancelled</Tag>; break;
            default: tag = null; break;
        }

        return tag;
    };

    const ordersColumns = [
        {
            title: 'Order',
            dataIndex: 'number',
            key: 'number',
            render: (d, s) => <Link to={`/orders/${s._id}`}>#{d}</Link>
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: d => moment(d).format("MMM DD, YYYY hh:mma")
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: d => `$${d.toFixed(2)}`
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: d => getOrderStatus(d)
        },
    ];

    const ordersPlaced = orders.length;
    const totalSpent = `$${orders.reduce((a, b) => a += b.total, 0).toFixed(2)}`;

    return (
        <>
            <Helmet>
                <title>{firstName} {lastName} - TextAnOffer</title>
            </Helmet>
            <PageHeader
                title={"Subscriber Information"}
                subTitle={name}
                onBack={() => window.history.back()}
                tags={getStatus(status)}
            />
            {loading ?
                <Skeleton />
                :
                <>
                    <Row>
                        <Col lg={8} md={8} xs={24} sm={24}>
                            <Card title="Messages Sent" style={{ marginTop: 8, marginRight: 30 }}>
                                <h1>{messages}</h1>
                            </Card>
                        </Col>
                        <Col lg={8} md={8} xs={24} sm={24}>
                            <Card title="Orders Placed" style={{ marginTop: 8, marginRight: 30 }}>
                                <h1>{ordersPlaced}</h1>
                            </Card>
                        </Col>
                        <Col lg={8} md={8} xs={24} sm={24}>
                            <Card title="Total Spent" style={{ marginTop: 8, marginRight: 30 }}>
                                <h1>{totalSpent}</h1>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg={24}>
                            <Card title={"Customer Orders"}>
                                <Table
                                    columns={ordersColumns}
                                    dataSource={orders}
                                />
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg={24}>
                            <Card title={"Shipping Information"}>
                                <Form
                                    layout={"vertical"}
                                    onFinishFailed={() => { }}
                                    onFinish={() => { }}
                                >
                                    <Input.Group size="large">
                                        <Form.Item label={"Phone number"}>
                                            <Input disabled placeholder={"Phone number"} value={phoneNumber} />
                                        </Form.Item>
                                    </Input.Group>
                                    <Input.Group size="large">
                                        <Row gutter={12}>
                                            <Col span={12}>
                                                <Form.Item label={"First name"}>
                                                    <Input placeholder={"First name"} value={firstName} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label={"Last name"}>
                                                    <Input placeholder={"Last name"} value={lastName} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Input.Group>
                                    <Input.Group size="large">
                                        <Form.Item label={"Business (optional)"}>
                                            <Input placeholder={"Business (optional)"} value={businessName} />
                                        </Form.Item>
                                    </Input.Group>
                                    <Input.Group size="large">
                                        <Form.Item label={"Address Line 1"}>
                                            <Input placeholder={"Address line one"} value={addressOne} />
                                        </Form.Item>
                                    </Input.Group>
                                    <Input.Group size="large">
                                        <Form.Item label={"Address line 2"}>
                                            <Input placeholder={"Address line two"} value={addressTwo} />
                                        </Form.Item>
                                    </Input.Group>
                                    <Input.Group size="large">
                                        <Row gutter={8}>
                                            <Col lg={8} md={24} sm={24} xs={24}>
                                                <Form.Item label="City">
                                                    <Input placeholder={"City"} value={city} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={8} md={24} sm={24} xs={24}>
                                                <Form.Item label="State">
                                                    <Input placeholder={"State"} value={state} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={8} md={24} sm={24} xs={24}>
                                                <Form.Item label="Zip">
                                                    <Input placeholder={"Zip code"} value={zipCode} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Input.Group>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}
