import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from 'universal-cookie';
import {
  Layout,
  Menu,
  Form,
  Input,
  Button,
  Alert,
  Row,
  Col,
  Carousel,
  notification
} from "antd";
import { Popconfirm, Space, Upload } from "antd";
import { AppFooter } from "../components/Footer";
import { Link, useParams } from "react-router-dom";
import jsonwebtoken from'jsonwebtoken';
const AppCookies = new Cookies();
const { Content, Header } = Layout;

export const ForgotPassword = (props) => {
  const [loading, setLoading] = useState(true);
  const [showFailedCredentials, setShowFailedCredentials] = useState(false);
  const [api, contextHolder] = notification.useNotification()
  const [message,setMessage] = useState("");
  const [isRest,setIsRest] = useState(false);
 
  
  const {key} = useParams();
  useEffect(() => {
    if(props.Cookies.get("resetauth")){
     
      setIsRest(true);
    }
    const checkIfTokenIsValid = async () => {
      const url = `${props.apiUrl}/merchant/verify/`;
      try {
        const redirectUrl = await axios.get(url);
        console.log("redirectUrl", redirectUrl);
        window.location.href = "/";
      } catch (e) {
        setLoading(false);
      }
    };
    if (props.Cookies.get("token")) checkIfTokenIsValid();
    else setLoading(false);
  }, []);

  const onFinish = async (values) => {
    const { email, password , confirm_password} = values;
    const resetAuth = generateToken(email)
    if(!isRest){
     const forgotLink = `${props.apiUrl}/merchant/sendforgetLink/`;
     const response = await axios.post(forgotLink, { email});
      props.Cookies.set("resetauth", resetAuth, { sameSite: "strict", secure: true });
    //  if(response && response.status === 200 )return api['success']({ message: response.data.message,});
     if(response.status === 200) {
      notification.success({  message: response.data.message})
      setTimeout(()=>{window.location.href= "/thankyou"}, 1000);
  }
    }else{
      const decode =decodeToken(props.Cookies.get("resetauth"))
      props.Cookies.remove('resetauth')
      if(decode === false){
        return api['error']({ message:'Please try again ! reset password link expired.'});
       }else{
        const forgotRoute = `${props.apiUrl}/merchant/forgot/`;
        try {
         setShowFailedCredentials(false);
          const response = await axios.post(forgotRoute, { email:decode.data, password ,confirm_password });
      

         if(response.status === 201) return api['info']({ message: response.data.message,});
         if(response.status === 200) {
           notification.success({  message: response.data.message})
           setTimeout(()=>{window.location.href = "/login"}, 1000);
       }
           
         } catch (e) {
           console.log(e.message)
           notification.info({  message: "Close The Tab or Refresh The Browser !"})
         setShowFailedCredentials(true);
       }
       }
    
    }

  };

  const generateToken = payload => {
   const jwt = jsonwebtoken.sign(
        { 
          data: payload ,

        },
        'TextAnOffer',
        { expiresIn:'1h' }
    );

    return jwt;
}


const decodeToken = token => {
  const signingKey = key || 'TextAnOffer' ;
  const jwt = jsonwebtoken.verify(token, signingKey);

  return jwt || false;
}

  const onFinishFailed = async (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const contentStyle = {
    height: "300px",
    color: "#fff",
    lineHeight: "300px",
    textAlign: "center",
    background: "#364d79",
  };

  return (
  
    !loading && (
      <Layout className="layout">
         {contextHolder}
        <Header>
          <div className="logo">
            <img
              src={"/logo.svg"}
              alt={"logo"}
              width={200}
              style={{ marginTop: "-18px" }}
            />
          </div>
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[0]}></Menu>
        </Header>
        <Content style={{ padding: "0 50px" }}>
          <div style={{ margin: "30px 0" }}></div>
          <div className="site-layout-content">
            <Row>
              <Col lg={6} md={8} xs={24} sm={24}>
                <h1>{isRest?"Reset Password":'Forgot Password'}</h1>
                {showFailedCredentials && (
                  <>
                    <Alert
                      message="Incorrect email address or password. Please try again."
                      type="error"
                    />
                    <br />
                  </>
                )}
                <Form
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                { isRest && key ? <>
                 
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password />
                    
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    name="confirm_password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Confirm password!",
                      },
                    ]}
                  >
                    <Input.Password />
                    
                  </Form.Item>
                  {/* <Form.Item>
                    <Button type="primary" htmlType="submit">Change</Button>
                  </Form.Item> */}
                <Space size={"large"} style={{ columnGap: 90 }}>
                  <Form.Item>
                  <Button type="primary" htmlType="submit">
                      Change
                    </Button>
                    </Form.Item>
                  
                  </Space>
                  </>: <><Form.Item
                    label="Email Address"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input />
                    
                  </Form.Item>
                    <Form.Item>
                    <Button type="primary" htmlType="submit">
                      send
                    </Button>
                  </Form.Item>
                  </>
                  }
                </Form>
         
              </Col>
              <Col lg={14} md={10} xs={20} sm={20} offset={4}>
                {/* <div>
                  <img src={"/forgot.jpg"} alt={"Logo"} style={contentStyle} />
                </div> */}
           
              </Col>
            </Row>
          </div>
        </Content>
        <AppFooter />
       
      </Layout>
    )





    
  );
};
