import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import {
  Table,
  Tag,
  Space,
  Input,
  PageHeader,
  Skeleton,
  Dropdown,
  Menu,
  notification,
  message,
  Popconfirm,
  Button,
  Progress,
  Spin,
  Upload,
  Col, Row, Form,
  Alert,
  Card,
  Radio
} from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from '@mui/material/Paper';
import { experimentalStyled as styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import { FormControl } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export const Wallets = (props) => {
  const [loading, setLoading] = useState(true);
  const [importing, setimporting] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState();
  const [transaction, setTransaction] = useState([]);
  const [amount, setAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [isShowWallet, setIsShowWallet] = useState(false);
  const [isShowRecharge, setIsShowRecharge] = useState(false);
  const [value, setValue] = useState();
  const [cards, setCards] = useState([]);
  const [alreadyCardUsed, setAlreadyCardUsed] = useState(true)
  const [checkedValue, setCheckedValue] = useState();
  useEffect(() => {
    fetchTransactionData();
    fetchCardData();
  }, []);
  const fetchTransactionData = async () => {
    const url = `${props.apiUrl}/wallet/transaction`;
    const response = await axios.get(url);
    const data = response.data;
    setTransaction(response.data)
    setLoading(false)
    if (data.length > 0) {
      setBalance(data[0].amount)
      setIsShowWallet(true)

    }
  }

  const fetchCardData = async () => {
    const url = `${props.apiUrl}/wallet/getcards`;
    const response = await axios.get(url);
    const data = response.data.data;
    if (data.length > 0) {
      setCheckedValue(data[0].id)
      setValue(data[0].id);
      setCards(data)
    }
  }

  const onChange = (e) => {
    console.log(" radio checked ", e.target.value);
    setValue(e.target.value);

    if (e.target.value === 4) {
      setAlreadyCardUsed(false)
    }
  };
  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return "";
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const url = `${props.apiUrl}/wallet/recharge`;


      if (alreadyCardUsed) {
        if (!value) {
          notification.error({ message: 'Please Provide Card Details Or Select The Existing One!' });
          setimporting(false)
          return
        }
        if (!amount) {
          return notification.error({ message: 'Please Provide Amount !' });
        } else if (amount < 5) {
          return notification.error({ message: 'Minimum Recharge Amount is 5$ !' });
        } else {
          setimporting(true)
          const response = await axios.post(url, { cardToken: null, amount, cardId: value });
          const data = response.data;
          if (response.status === 200) {
            setPaymentIntent(response.data)
            return notification.success({ message: 'Thank you ! Recharge Succesfully .' });
          }
          setimporting(false)
        }


      } else {
        const cardElement = elements.getElement(CardElement);
        const result = await stripe.createToken(cardElement);
        const cardToken = result?.token.id
        if (!result.token.id) {
          return notification.error({ message: 'Please Provide Card Details !' });
        } else if (!amount) {
          return notification.error({ message: 'Please Provide Amount !' });
        } else if (amount < 5) {
          return notification.error({ message: 'Minimum Recharge Amount is 5$ !' });
        }
        else {
          setimporting(true)
          const response = await axios.post(url, { cardToken, amount, cardId: null });
          const data = response.data;
          console.log("data =>", data);
          if (response.status === 200) {
            setPaymentIntent(response.data)
            return notification.success({ message: 'Thank you ! Recharge Succesfully .' });
          }
          setimporting(false)
        }

      }
    } catch (error) {
      notification.error({ message: 'Please Provide Card Details Or Select The Existing One!' });
      setimporting(false)
      console.log("Error =>", error.message);
    }
  }

  if (paymentIntent && paymentIntent.status === "succeeded") {
    return (
      <>
        <Redirect to={{ pathname: "/" }} />
      </>
    );
  }
  const columns = [
    {
      title: "Status",
      dataIndex: "amount",
      key: "amount",
      render: (amount) =>
        amount > 0 ? (
          <Tag color={"green"}>Active</Tag>
        ) : (
          <Tag color={"volcano"}>Used</Tag>
        ),
    },
    {
      title: "Recharged ",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (d) => moment(d).fromNow(),
    },
    {
      title: "Message Send",
      key: "message_send",
      dataIndex: "message_send",
    },
    {
      title: "Recharged Amount",
      key: "charged_amount",
      dataIndex: "charged_amount",
      render: (d) => `$${d.toFixed(2)}`,
    },
    {
      title: "Current Amount",
      key: "amount",
      dataIndex: "amount",
      render: (d) => `$${d.toFixed(2)}`,
    },

  ];
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const showRecharge = () => {
    setIsShowRecharge(true)
  }

  return (
    <>
     {importing && <Spin size="large" style={{ marginTop: '1px' }}>
        <div className="content" />
      </Spin>}
      {isShowWallet && balance === 0 ? <Alert
        message={`Your Wallet is Runing With Insufficient Balance $ ${balance.toFixed(2)} .Please Recharge Now !`}
        type={'info'}
      /> : isShowWallet && balance < 5 ? <Alert

        message={`Your Wallet is Runing With Insufficient Balance $ ${balance.toFixed(2)} .`}
        type={'warning'}
      /> : null

      }
        <br/>
      {
        !cards || cards.length === 0 && <Alert
           message={`You have not yet added a payment method to your account, please click 'Recharge' to add one now!`}
          type={'warning'}
        />
      }

      {!isShowRecharge && <Box style={{ marginTop: 20 }}>
        <Row style={{ marginLeft: 140 }}>
          <Col span={12} offset={6}>
            <Card
              title="Wallet Balance"
              extra={<a href="#" onClick={() => { showRecharge() }}>Recharge</a>}
              style={{
                width: 300,
              }}
            >
              <p> $ {balance.toFixed(2)} </p>

            </Card>
          </Col>
        </Row>
      </Box>}

      <PageHeader
        title={!isShowRecharge ? "Transaction" : "Recharge"}
        subTitle={!isShowRecharge ? "Here is a list of your previous Transaction" : "Here You Use Previous Card Method Or Can Use New Card !"}
      />
      {loading ? (
        <Skeleton />
      ) : !isShowRecharge && (
        <Table columns={columns} dataSource={transaction} />
      )}


      {isShowRecharge &&

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid item sm={12} xs={6} sx={{ marginTop: "10px", marginLeft: '30px' }}>
                <Radio.Group onChange={onChange} value={value}>
                  <Space direction="horizontal" size={100} >
                    <Radio value={checkedValue}>Save Cards</Radio>
                    <Radio value={4}>

                      Use New Card
                      {value === 4 ? (
                        <FormControl style={{
                          width: 300,
                          marginTop: 10,
                        }} className="filter ml-0 w-100 country">
                          <CardElement />
                        </FormControl>
                      ) : null}
                    </Radio>
                  </Space>
                </Radio.Group>


              </Grid>
              {value != 4 && <Grid item sm={12} xs={6} sx={{ marginTop: "10px", marginLeft: '30px' }}>
                <Radio.Group onChange={onChange} value={checkedValue}>
                  <Space direction="vertical">

                    {cards && cards?.map(card => {
                      return (<Radio value={card.id}>{card.brand} ...{card.last4}</Radio>)
                    })}
                    {/* <Radio value={1}>A</Radio>
                  <Radio value={2}>B</Radio>
                  <Radio value={3}>C</Radio> */}
                  </Space>
                </Radio.Group>
              </Grid>}

            </Grid>
            <Grid item xs>
              <TextField
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                style={{
                  marginTop: 25,
                }}
                size="small"
                label="Amount*"
                name="amount"

              />
            </Grid>

            <Grid item xs>
              <Button
                style={{
                  marginTop: 25,
                }}
                onClick={(e) => {
                  handleSubmit(e);
                }}
                variant="contained"
                component="label"
                type="primary"
              >
                Recharge Now
              </Button>
            </Grid>
          </Grid>
        </Box>


        //  <Box sx={{ flexGrow: 1 }}>
        //        <Grid container spacing={3}>
        //       <Grid item xs={6}>
        //         <Grid item sm={12} xs={6} sx={{ marginTop: "10px", marginLeft:'30px'}}>
        //                 <FormControl fullWidth className="filter ml-0 w-100 country">
        //                   <CardElement />
        //                 </FormControl>
        //               </Grid> 
        //         </Grid>
        //         <Grid item xs>
        //         <TextField
        //                   onChange={(e) => {
        //                     setAmount(e.target.value);
        //                   }}
        //                 size="small"
        //                   label="Amount*"
        //                   name="amount"

        //                 />
        //         </Grid>

        //         <Grid item xs>
        //           <Button
        //                onClick={(e) => {
        //                     handleSubmit(e);
        //                   }}
        //                   variant="contained"
        //                   component="label"
        //                   type="primary"
        //                 >
        //                Recharge Now
        //                 </Button>
        //         </Grid>
        //       </Grid>
        //     </Box>

      }

    </>
  );
};
