import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Redirect } from "react-router-dom";

import axios from "axios";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import { Button, Typography, FormControl, InputLabel } from "@mui/material";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";

import countriesArray from "./../../countries";
import { Helmet } from "react-helmet";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const Billing = (props) => {
  const [prices, setPrices] = useState();
  const [sumOforder,setSumOfOrder]=useState();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [clientSecret] = useState();
  // const [subscriptionId] = useState();
  const [messages, _setMessages] = useState("");
  const [paymentIntent, setPaymentIntent] = useState();
  const [customerId, setCustomerId] = useState();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState();
  const [country, setCountry] = useState();

  const [line1, setLine1] = useState();
  const [line2, setLine2] = useState();
  const [postal_code, setPostalCode] = useState();
  const [state, setState] = useState();

  const [countries, setCountries] = useState();
  const [states, setStates] = useState();
  const [isError, setIsError] = useState(true);
  const [open, setOpen] = useState(false);
  const [merchant, setMerchant] = useState();
  const [subscriptionId, setSubscriptionId] = useState();
  const [loading, setLoading] = useState(true);
  const [isLoading, setISLoading] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber,setPhoneNumber]=useState();
  useEffect(() => {
    const fetchPrices = async () => {
      const url = `${props.apiUrl}/subscriber/config`;
      await axios.get(url).then((r)=>{
        console.log("sumTotal => ",r.data.sumTotal);
         setSumOfOrder(r?.data.sumTotal)
         setPrices(r?.data.prices[0]);
      }).catch((error)=>{
        console.log("Error =>",error.message);
      });
      
   
      // setPrices(prices[0]);
      // const { prices } = await fetch(`${props.apiUrl}/subscriber/config`).then(
      //   (r) => r.json()
      // );
      // setPrices(prices[0]);
    };
    const fetchmerchant = async () => {
      const url = `${props.apiUrl}/merchant/dashboard/`;
      const response = await axios.get(url);
      const merchant = response.data.merchant;
      console.log("merchant =>",merchant);
      setMerchant(merchant);
      setCustomerId(merchant.stripe.customer.id);
      setEmail(merchant.email);
      setName(merchant.name);
      setPhone(merchant.phoneNumber);
      setFirstName(merchant?.owner.firstName);
      setLastName(merchant?.owner.lastName)
    };

    setCountries(countriesArray.countries);
    fetchPrices();
    fetchmerchant();
  }, []);

  // helper for displaying status messages.
  const setMessage = (message) => {
    _setMessages(`${messages}\n\n${message}`);
  };

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return "";
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (e, priceId) => {
    setISLoading(true);
    e.preventDefault();
    if (
      !firstName ||
      !lastName ||
      !city ||
      !state ||
      !postal_code ||
      !line1 ||
      !country ||
      !phone

    ) {
      setIsError(true);
      setOpen(true);
      setLoading(true);
      setISLoading(false);
    } else {
      setISLoading(true);

      const cardElement = elements.getElement(CardElement);
      const result = await stripe.createToken(cardElement);
     

      var data = {
        phoneNumber: phone,
        firstName: firstName,
        lastName: lastName,
        addressOne: line1,
        addressTwo: line2 || "",
        businessName: merchant?.subdomain,
        zipCode: postal_code,
        city: city,
        state: state,
        merchant: merchant?._id,
      };

      const { subscriptionId, clientSecret  } = await fetch(
        `${props.apiUrl}/subscriber/subscribe129`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            priceId,
            customerId,
            data,
            sumTotal:sumOforder,
            cardToken:result.token.id
          }),
        }
      ).then((r) => r.json());

     // Use card Element to tokenize payment details
      let { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              address: {
                city: city,
                country: country,
                line1: line1,
                line2: line2,
                postal_code: postal_code,
                state: state,
              },
              email: email,
              name: email,
              phone: phone,
            },
          },
        }
      );

      if (error) {
        // show error and collect new card details.
        console.log(error.message);
        setMessage(error.message);
        return;
      }
      setSubscriptionId(subscriptionId);
      setPaymentIntent(paymentIntent);
      setOpen(true);
      setIsError(false);
    }
  };

  if (paymentIntent && paymentIntent.status === "succeeded") {
    return (
      <>
        <Redirect to={{ pathname: "/" }} />
      </>
    );
  }

  const handleCountry = async (e) => {
    const countryName = e.target.value;
    const stateBasedOnCountry = countriesArray.countries.filter((e) => {
      if (e.iso2 === countryName) {
        return e.states;
      }
    });
    // console.log(stateBasedOnCountry[0].states)
    setStates(stateBasedOnCountry[0].states);
    setCountry(countryName);
  };

  const handleState = async (e) => {
    console.log("state", e.target.value);
    const stateName = e.target.value;
    setState(e.target.value);
  };

  return (
    <>
      {loading ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={1000}
          onClose={handleClose}
          key={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={isError ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {isError
              ? "Inputs Are Required "
              : "Thank You for Subscribing to Text An Offer"}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
        <Alert severity="info">Subscriber ! Apart from this subscription cost, we will charge you per sending messages of your campaign.</Alert>
      <Grid container rowSpacing={10} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
        <Grid item xs={6}>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={3} rowSpacing={1}>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="firstName"
                  id="outlined-disabled"
                  label="First Name"
                  value={`${merchant?.owner.firstName}`}
                  disabled
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                 
                />
           
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  fullWidth
                  id="outlined-disabled"
                  name="lastName"
                  label="Last Name"
                  value={`${merchant?.owner.lastName}`}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ marginTop: "3px" }}>
              <Grid item sm={12} xs={12}>
                <FormControl fullWidth className="filter ml-0 w-100 country">
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>

                  <Select
                    required
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    // value={countryy}
                    label="country *"
                    onChange={(e) => {
                      handleCountry(e);
                    }}
                  >
                    {countries &&
                      countries.map((item) => {
                        return (
                          <MenuItem key={item.name} value={item.iso2}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ marginTop: "3px" }}>
              <Grid item sm={12} xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Street Line 1"
                  name="address1"
                  onChange={(e) => setLine1(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ marginTop: "3px" }}>
              <Grid item sm={12} xs={12}>
                <TextField
                  fullWidth
                  label="Street Line 2 (optional)"
                  name="address2"
                  onChange={(e) => setLine2(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ marginTop: "3px" }}>
              <Grid item sm={12} xs={12}>
                <TextField
                  value={`${phone}`}
                  id="outlined-disabled"
                  disabled
                  required
                  fullWidth
                  label="Phone Number"
                  name="phone number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ marginTop: "3px" }}>
              <Grid item sm={4} xs={12}>
                <TextField
                  required
                  fullWidth
                  label="city"
                  name="city"
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth className="filter ml-0 w-100 country">
                  <InputLabel id="demo-simple-select-label">State</InputLabel>

                  <Select
                    required
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    // value={countryy}
                    placeholder=" select state"
                    label="state"
                    onChange={(e) => {
                      handleState(e);
                    }}
                  >
                    {states &&
                      states?.map((item) => {
                        return (
                          <MenuItem key={item.name} value={item.state_code}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  required
                  fullWidth
                  label="zip code"
                  name="zip code"
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ mt: 3 }}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography gutterBottom variant="h5" component="div">
                  Premium Plan
                </Typography>
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h6" component="div">
                  ${prices?.unit_amount / 100} / Month
                </Typography>
              </Grid>
            </Grid>

            <Grid container alignItems="center" sx={{ mt: 8 }}>
              <Grid item xs>
                <Typography gutterBottom variant="h7" component="div">
                  TEXT AN OFFER
                </Typography>
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h8" component="div">
                  ${prices?.unit_amount / 100}
                </Typography>
              </Grid>
            </Grid>
            <Divider />

            <Grid container alignItems="center" sx={{ mt:7 }}>
              <Grid item xs>
                <Typography gutterBottom variant="h10" component="div">
                  Subtotal
                </Typography>
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h10" component="div">
                  ${prices?.unit_amount / 100}
                </Typography>
              </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={3} sx={{ marginTop: "10px" }}>
              <Grid item sm={12} xs={12}>
                <Typography gutterBottom variant="h10" component="div">
                  Card Details
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ marginTop: "10px" }}>
              <Grid item sm={12} xs={12}>
                <FormControl fullWidth className="filter ml-0 w-100 country">
                  <CardElement />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ marginTop: "70px" }}>
              <Grid item sm={12} xs={12}>
                {/* <Button
              
                onClick={(e) => {
                  handleSubmit(e, prices.id);
                }}
                style={{ backgroundColor: "#EA4522",width:'100%' }}
                variant="contained"
              >
                Subscribe
              </Button> */}
                <LoadingButton
                  loading={isLoading}
                  loadingPosition="center"
                  onClick={(e) => {
                    handleSubmit(e, prices.id);
                  }}
                  style={{ backgroundColor: "#EA4522", width: "100%" }}
                  variant="contained"
                >
                  Subscribe
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs>
          <Typography
            style={{ "font-size": "14px" }}
            gutterBottom
            component="div"
          >
            We hope you don't leave us but we understand if you do have to go.
            To cancel your subscription, please email us at
            <a> admin@textanoffer.com </a>
            with 'Cancel' in the Subject line
          </Typography>
         
        </Grid>
      </Grid>
    </>
  );
};

export default Billing;
