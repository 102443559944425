import React, { useEffect, useState } from "react";
import CSVFileValidator from 'csv-file-validator'
import {
  Table,
  Tag,
  Space,
  Input,
  PageHeader,
  Skeleton,
  Dropdown,
  Menu,
  notification,
  message,
  Popconfirm,
  Button,
  Progress,
  Spin,
  Upload,
  Col, Row, Form,
  Alert
} from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { Link ,Redirect} from "react-router-dom";
import { Helmet } from "react-helmet";

import axios from "axios";
import moment from "moment";
import { useCSVReader } from "react-papaparse";
const { Search } = Input;

export const Subscribers = (props) => {
  const { CSVReader } = useCSVReader();
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [merchant, setMerchant] = useState();
  const uploadRef = React.useRef(null);
  const [fileHeader, setFileHeader] = useState([
    "phoneNumber",
    "firstName",
    "lastName",
    "businessName",
    "addressOne",
    "addressTwo",
    "city",
    "state",
    "zipCode"
   
  ]);
  const [alreadyNumber, setAlreadyNumber] = useState();
  const [importing,setimporting] = useState(false);
  const [percentupload ,setPercentUpload] = useState(0);
  const [balance, setBalance] = useState(0);
  const [isShowWallet , setIsShowWallet] = useState(false);
  useEffect(() => {
    fetchMerchant();
    fetchSubscribers();
    fetchTransactionData();
  }, []);
  const fetchMerchant = async () => {
    const url = `${props.apiUrl}/merchant/dashboard/`;
    const response = await axios.get(url);
    setMerchant(response.data.merchant);
  };
  const fetchTransactionData = async () => {
    const url = `${props.apiUrl}/wallet/transaction`;
    const response = await axios.get(url);
    const data = response.data;
    console.log("data",data.length);
    if(data.length > 0){
        setBalance(data[0].amount)
        setIsShowWallet(true)

    }
}
  const fetchSubscribers = async () => {
    const phoneNumber = [];
    const url = `${props.apiUrl}/subscriber/list/`;
    const response = await axios.get(url);
    for (var i = 0; i < response.data.subscribers.length; i++) {
      phoneNumber.push(response.data.subscribers[i].phoneNumber);
    }

    setAlreadyNumber(phoneNumber);
    setSubscribers(response.data.subscribers);
    setLoading(false);
  };

  const deletedSubscriber = async (subscriberId) => {
    try {
      const url = `${props.apiUrl}/subscriber/delete/${subscriberId}/`;
      const response = await axios.put(url);
      if (response && response.status === 201)
        return notification.info({ message: response.data.message });
      if (response && response.status === 200) {
        notification.success({ message: response.data.message });
        fetchSubscribers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const menu = (subscriberId) => (
    <Menu
      onClick={() => {
        deletedSubscriber(subscriberId);
      }}
    >
      <Menu.Item key="1">Delete</Menu.Item>
    </Menu>
  );

  const confirm = (e) => {
    setIsUpload(true);
    message.success("Now You Can Upload Csv File !");
  };
  const cancel = (e) => {
    setIsUpload(false);
    message.error("you are not certify !");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "name",
      render: (text, data) => (
        <Link to={`/subscribers/${data._id}`}>
          {data.firstName} {data.lastName}
        </Link>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "nationalFormat",
      key: "nationalFormat",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) =>
        status === "subscribed" || 'pending' ? (
          <Tag color={"green"}>Subscribed</Tag>
        ) : (
          <Tag color={"volcano"}>Unsubscribed</Tag>
        ),
    },
    {
      title: "Signed Up",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (d) => moment(d).fromNow(),
    },
    {
      title: "Orders Placed",
      key: "orders",
      dataIndex: "orders",
    },
    {
      title: "Amount Spent",
      key: "amount",
      dataIndex: "amount",
      render: (d) => `$${d.toFixed(2)}`,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown.Button overlay={menu(record._id)}>
            <Link to={`/subscribers/${record._id}`}>View</Link>
          </Dropdown.Button>
        </Space>
      ),
    },
  ];

  const handleFileImport = async (data) => {
   
    var subscriberToImport = [];
    var upcomingNumber = [];
    for (var k = 0; k < data.length; k++) {
      upcomingNumber.push(`+1${data[k]['phoneNumber']}`);
    }
  const found = alreadyNumber.find((val, index) => {
      return upcomingNumber.includes(val);
    });
  
    
 if (typeof found != "undefined") {
      message.error(`phone Number already exist in DB!`);
      setIsUpload(false);
    } else {
 
      for (var i = 0; i < data.length; i++) {
        const customerInfo = {};
          for(var key in data[i]){
            customerInfo["merchant"] = merchant._id;
            if(key === 'phoneNumber'){
                  customerInfo[key] = `+1${data[i][key]}`
                }else{
                  customerInfo[key] = data[i][key];
                }
          }
        subscriberToImport.push(customerInfo);
      }
     
        console.log("subscriberToImport ==> ",subscriberToImport)
        setimporting(true)
        const url = `${props.apiUrl}/subscriber/createByCsv/`;
        const response = await axios.post(url, subscriberToImport);
        setIsUpload(false);
        setimporting(false)
        return response
        // notification.success({ message: "subscribers has import !"});
    
 }
 };

    const requiredError = (headerName, rowNumber, columnNumber) => {
      return `${headerName} is required in the ${rowNumber} row , ${columnNumber} column`
    }

    const validateError = (headerName, rowNumber, columnNumber) => {
      return `${headerName} is not valid in the ${rowNumber} row ,${columnNumber} column`
    }
    
    const uniqueError = (headerName, rowNumber) => {
      return `${headerName} is not unique at the ${rowNumber} row`
    }
    
    const isphonenumberValid = function (number) {
      const reqExp = /^\d{10}$/
      return reqExp.test(number)
    } 

    const CSVConfig = {
      headers: [
        { name: 'phoneNumber', inputName: 'phoneNumber', required: true, requiredError, unique: true, uniqueError, validate: isphonenumberValid, validateError },
        { name: 'firstName', inputName: 'firstName', required: true, requiredError },
        { name: 'lastName', inputName: 'lastName', required: true, requiredError, optional: true },
        { name: 'businessName', inputName: 'businessName',required: false },
        { name: 'addressOne', inputName: 'addressOne',required: false },
        { name: 'addressTwo', inputName: 'addressTwo',required: false },
        { name: 'city', inputName: 'city',required: false },
        { name: 'state', inputName: 'state',required: false },
        { name: 'zipCode', inputName: 'zipCode',required: false },
      ]
    }


   
   const  onChange = async( file ) =>{
     const data = await validate(file)
     console.log("data.inValidData ",data.inValidData)
     if(data.inValidData.length > 0){
       notification.info({ message: "Csv File Data Is Not Valid !"});
       
      }else{
        const importsubScriber = await handleFileImport(data.data);
         console.log("importsubScriber ",importsubScriber);
         if(importsubScriber.status === 200){
           notification.success({ message: "subscribers has import !"});
          }else{
            notification.info({ message: importsubScriber.data.message});
          }
        // await fetchSubscribers();
      }
    }
    
 

    const validate = async(file)=>{
    const csvData = await CSVFileValidator(file, CSVConfig);
    csvData.inValidData.forEach(item => {
      message.error(item.message)
      setIsUpload(false)			
    })
   
    return csvData
    }

  return (
    <>
   
 {importing &&  <Spin  size="large" style={{marginTop:'1px'}}>
        <div className="content" />
      </Spin>}
      { isShowWallet &&  balance === 0 ?  <Alert
                action={
                    <Button type={"link"}>
                        <Link to={'/wallet'}>Recharge Now</Link>
                    </Button>
                }
                message={ `Your Wallet is Runing With Insufficient Balance $ ${balance.toFixed(2)} .Please Recharge Now !`}
                type={ 'error'}
            /> :  isShowWallet && balance < 5  ?  <Alert
            action={
                <Button type={"link"}>
                    <Link to={'/wallet'}>Recharge Now</Link>
                </Button>
            }
            message={ `Your Wallet is Runing With Insufficient Balance $ ${balance.toFixed(2)} .`}
            type={ 'warning'}
        />  : null
          
          }
      <Row>
        <Col span={18 } >
        <Helmet>
        <title>Subscribers - TextAnOffer</title>
      </Helmet>
      
      <PageHeader
        title={"Subscribers"}
        subTitle={"Here is a list of your subscribers"}
      />
        </Col>
        <Col span={6} style={{padding: '25px '}}>
        {!isUpload ? (
                <Popconfirm
                  title="I certify that I have the attached subscribers' information through legitimate marketing efforts and have been given express permission to contact these subscribers via SMS"
                  onConfirm={confirm}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                  onOpenChange={() => console.log("open change")}
                >
                  <Button
                    style={{
                      height: "34px",
                      cursor: "pointer",
                    }}
                    type="primary"
                  >
                    Import
                  </Button>
                  
                </Popconfirm> 
              ) : (
                <Form>
               <Form.Item>
              <Button style={{ height: "34px",cursor: "pointer"}} type="primary"
                onClick={() => {
                  uploadRef.current.click();
                }}
                loading={importing}
                >
                Upload
              </Button>
              <input
                id="myInput"
                type="file"
                accept=".csv"
                ref={uploadRef}
                style={{ display: "none" }}
                onChange={(e)=>{onChange(e.target.files[0])}}
              />
             </Form.Item>
           
          </Form> )
              } 
          { !isUpload && <a href="/testData.csv" download>
                <Button
                  style={{
                    borderRadius: 0,
                    padding: "0 20px",
                    color: "#ea4522",
                    cursor: "pointer",
                    height: 34,
                  }}
                  variant="contained"
                  component="label"
                >
                Sample .csv File
                </Button>
              </a>}

        </Col>
      </Row>

      

      <Search placeholder="Search for subscriber" onSearch={() => {}} />
      <br />
      <br />
      {loading ? (
        <Skeleton />
      ) : (
        <Table columns={columns} dataSource={subscribers} />
      )}
    </>
  );
};
