import React from 'react';

import { Alert, Button, PageHeader } from 'antd';
import { Link } from 'react-router-dom';

export const Payments = (props) => {
    return (
        <>
            <PageHeader title={"Payments"} subTitle={"Here is a list of your payments"} />
            <Alert
                action={
                    <Button type={"link"}>
                        <Link to={'/payments/connect'}>Connect bank account</Link>
                    </Button>
                }
                message={"You need to connect your bank account to begin receiving payments."}
                type={"warning"}
            />
        </>
    )
}
