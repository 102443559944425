import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Redirect, useLocation } from "react-router-dom";

const useQuery = () => new URLSearchParams(useLocation().search);

export const SquareCallback = (props) => {
    const query = useQuery();
    const [pathname, setPathname] = useState("");
    useEffect(() => {
        const createPathname = async () => {
            const backendUrl = `${props.apiUrl}/square/install?&code=${query.get('code')}`;
            const errorUrl = `/error?error=${query.get('error')}`;

            const stateMatches = props.Cookies.get('AUTH_STATE') === query.get('state');
            const authHadSomeError = query.get('error');
            const queryHasCode = query.get('response_type') === 'code';

            const approvedRequest = stateMatches && !authHadSomeError && queryHasCode;

            if (approvedRequest) {
                const response = await axios.get(backendUrl);
                const token = response.data.token;
                props.Cookies.set('token', token, { sameSite: 'strict', secure: true });

                setPathname('/');
            } else {
                setPathname(errorUrl);
            }
        }
        createPathname();
    }, [])
    return <Redirect to={{ pathname }} />;
}
