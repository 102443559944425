import React, { useEffect, useState } from 'react';
import axios from 'axios';
import md5 from 'md5';

import { PageHeader, Skeleton, Button, Alert } from 'antd';

import './stripe-connect.css';

export const Connect = (props) => {
    const [stripeAccountLink, setStripeAccountLink] = useState("");
    const [accountConnected, setAccountConnected] = useState(false);
    const [emailRequired, setEmailRequired] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            const state = md5(Date.now());
            const url = `${props.apiUrl}/stripe/link/?state=${state}`;
            props.Cookies.set('STRIPE_AUTH_STATE', state);

            const response = await axios.get(url);
            if (response.data.connected) {
                setAccountConnected(true);
                setLoading(false);
            } else {
                setStripeAccountLink(response.data.link);
                setEmailRequired(response.data.emailRequired);
                setLoading(false);
            }
        }
        

        fetchData();
    }, []);

    const connectAccount = async () => {
               // console.log("&&&&",stripeAccountLink)
               window.location.href = stripeAccountLink;
        // res.setHeader("Access-Control-Allow-Origin", "*");
    };

    return (
        <>
            <PageHeader title={"Connect Your Bank Account"} onBack={() => window.location.href = '/'} />
            {loading ?
                <Skeleton />
                :
                !accountConnected ?
                    emailRequired ?
                        <VerificationRequired connectAccountAction={connectAccount} />
                        :
                        <ConnectAccount connectAccountAction={connectAccount} />
                    :
                    <Alert message={"Your bank account is connected!"} type={"success"} />
            }
        </>
    )
}

const VerificationRequired = (props) => {
    return (
        <>
            <p>Additional verification is required from Stripe. Click Proceed With Stripe to finish your verification.</p>
            <Button onClick={props.connectAccountAction} type={"link"} className="stripe-connect"><span>Proceed With Stripe</span></Button>
        </>
    )
}

const ConnectAccount = (props) => {
    return (
        <>
            <p>We use Stripe to process our payments. Please click this button to connect your bank account with Stripe.</p>
            <Button onClick={props.connectAccountAction} type={"link"} className="stripe-connect"><span>Connect with Stripe</span></Button>
        </>
    )
}
