import React, { useEffect } from 'react';
import md5 from 'md5';

const scopes = 'ITEMS_READ+MERCHANT_PROFILE_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE+PAYMENTS_READ';
const squareAppId = 'sandbox-sq0idb-C1F9JyjHEurAqouosVMCbg';

export const SquareAuth = (props) => {
    useEffect(() => {
        const authenticateSquareUser = async () => {
            const basePath = `https://connect.squareupsandbox.com`;
            const uri = `/oauth2/authorize`;
            const state = md5(Date.now());
            const queryString = `?client_id=${squareAppId}&response_type=code&scope=${scopes}&state=${state}`;
            const accessUrl = `${basePath}${uri}${queryString}`;
            
            props.Cookies.set('AUTH_STATE', state, { sameSite: 'strict', secure: true });
            window.location.href = accessUrl;
        }

        authenticateSquareUser();
    }, []);
    return <div></div>;
}
