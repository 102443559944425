import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Layout,
  Menu,
  Form,
  Input,
  Button,
  Alert,
  Row,
  Col,
  Carousel,
  Space,
  Image,
} from "antd";

import { AppFooter } from "../components/Footer";
import { Link } from "react-router-dom";

const { Content, Header } = Layout;

export const Login = (props) => {
  const [loading, setLoading] = useState(true);
  const [showFailedCredentials, setShowFailedCredentials] = useState(false);

  useEffect(() => {
    
    const checkIfTokenIsValid = async () => {
      const url = `${props.apiUrl}/merchant/verify/`;
      try {
        const redirectUrl = await axios.get(url);
        console.log("redirectUrl", redirectUrl);
        window.location.href = "/";
      } catch (e) {
        setLoading(false);
      }
    };
    if (props.Cookies.get("token")) checkIfTokenIsValid();
    else setLoading(false);
  }, []);

  const onFinish = async (values) => {
    const { email, password } = values;

    const loginRoute = `${props.apiUrl}/merchant/login/`;
    try {
      setShowFailedCredentials(false);

      const response = await axios.post(loginRoute, { email, password });
      const token = response.data.token;
      props.Cookies.set("token", token, { sameSite: "strict", secure: true });

      window.location.href = "/";
    } catch (e) {
      setShowFailedCredentials(true);
    }
  };

  const onFinishFailed = async (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const contentStyle = {
    height: "300px",
    color: "#fff",
    lineHeight: "300px",
    textAlign: "center",
    background: "#364d79",
  };

  return (
    !loading && (
      <Layout className="layout">
        <Header>
          <div className="logo">
            <img
              src={"/logo.svg"}
              alt={"logo"}
              width={200}
              style={{ marginTop: "-18px" }}
            />
          </div>
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[0]}></Menu>
        </Header>
        <Content style={{ padding: "0 50px" }}>
          <div style={{ margin: "30px 0" }}></div>
          <div className="site-layout-content">
            <Row>
              <Col lg={6} md={8} xs={24} sm={24}>
                <h1>Login</h1>
                {showFailedCredentials && (
                  <>
                    <Alert
                      message="Incorrect email address or password. Please try again."
                      type="error"
                    />
                    <br />
                  </>
                )}
                <Form
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="Email Address"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Space size={"large"} style={{ columnGap: 83 }}>
                    {/* <Form.Item>
                      <Link to={"/forgot"}>
                        {" "}
                        <Button type="primary" htmlType="submit">
                          Forgot Password
                        </Button>
                      </Link>
                    </Form.Item> */}
                    <Form.Item>
                      <Link to={"/register"}>
                        {" "}
                        <Button type="primary" htmlType="submit">
                          Create account
                        </Button>
                      </Link>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Sign in
                      </Button>
                    </Form.Item>
                  </Space>
                </Form>
                <Link to={"/forgot"}>Forgot Password</Link>
                {/* <a href="/forgot">Forgot password</a> */}
              </Col>
              <Col lg={14} md={10} xs={20} sm={20} offset={4}>
                <div>
                  {/* <h3 style={contentStyle}>1</h3> */}
                  {/* <img src={"/login.jpg"} alt={"Logo"} style={contentStyle} /> */}
                </div>
                {/* <Carousel autoplay>
                                <div>
                                    <h3 style={contentStyle}>1</h3>
                                </div>
                                <div>
                                    <h3 style={contentStyle}>2</h3>
                                </div>
                                <div>
                                    <h3 style={contentStyle}>3</h3>
                                </div>
                                <div>
                                    <h3 style={contentStyle}>4</h3>
                                </div>
                            </Carousel> */}
              </Col>
            </Row>
          </div>
        </Content>
        <AppFooter />
      </Layout>
    )
  );
};
