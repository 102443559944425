import React, { useEffect } from 'react';

import axios from 'axios';
import md5 from 'md5';

export const StripeReauth = (props) => {
    useEffect(() => {
        const fetchReauthUrl = async () => {
            const state = md5(Date.now());
            props.Cookies.set('STRIPE_AUTH_STATE', state);

            const url = `${props.apiUrl}/stripe/reauth?state=${state}`;
            const response = await axios.get(url);
            console.log(response.data)
            window.location.href = response.data.url;
        }

        fetchReauthUrl();
    }, []);
    return <></>
}
