import React from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';

import './index.less';
import 'antd/dist/antd.less';

import { Helmet } from 'react-helmet';
import { Route, BrowserRouter, Switch } from 'react-router-dom';

import { SquareAuth } from './components/HOC/square/Auth';
import { SquareCallback } from './components/HOC/square/Callback';

import { StripeReauth } from './components/HOC/stripe/Reauth';
import { StripeCallback } from './components/HOC/stripe/Callback';

import { ProtectedRoute } from './components/HOC/ProtectedRoute';

import { Login } from './pages/Login';
import { ForgotPassword } from './pages/ForgotPassword'
import { Thankyou } from './pages/Thankyou'
import { Register } from './pages/Register';
import { Error } from './pages/Error';

import { Dashboard } from './pages/Dashboard';
import { Subscribers } from './pages/subscribers/Index';
import { Orders } from './pages/orders/Index';
import { Payments } from './pages/payments/Index';
import { Campaigns } from './pages/campaigns/Index';
import { Wallets } from './pages/wallet/Index'
import { Create } from './pages/campaigns/Create';
import { CampaignView } from './pages/campaigns/View';
import { OrdersView } from './pages/orders/View';
import { SubscriberView } from './pages/subscribers/View';
import { Connect } from './pages/payments/Connect';
import { Logout } from './pages/Logout';
import { Embed } from './pages/Embed';
import Billing from './pages/payments/Billing';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { config } from 'dotenv';
// const apiUrl = 'http://localhost:7777';
// var STRIPE_PUBLISHABLE_KEY= 'pk_live_51IAJtDCuH1JmFwY37pxZEbBVCsbYVz9BUqCImzP1IXhyE4pLKZCtCAMQB4Y7nWDvOqnRQ7hWl8YOuBaZ4ngVqbgW00Z0o5xrcA'

const apiUrl = 'https://api.textanoffer.com';
const AppCookies = new Cookies();
axios.defaults.headers.common = { "X-JWT-TOKEN": AppCookies.get("token") };
axios.defaults.timeout = 180000;
var stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const location = window.location.pathname;

ReactDOM.render((
  <>
    <Helmet>
      <title>TextAnOffer.com: Text Message Sales</title>

    </Helmet>

    <BrowserRouter>

      <Switch>
        <Route path="/thankyou" component={() => <Thankyou Cookies={AppCookies} />} />

        <Route path="/auth" component={() => <SquareAuth Cookies={AppCookies} />} />
        <Route path="/callback" component={() => <SquareCallback apiUrl={apiUrl} Cookies={AppCookies} />} />
        <Route path="/error" component={() => <Error />} />
        <Route path="/login" component={() => <Login apiUrl={apiUrl} Cookies={AppCookies} />} />
        <Route path="/forgot/:key?" component={() => <ForgotPassword apiUrl={apiUrl} Cookies={AppCookies} />} />
        <Route path="/register" component={() => <Register apiUrl={apiUrl} Cookies={AppCookies} />} />
        <Route path="/logout" component={() => <Logout Cookies={AppCookies} />} />
        <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies} exact={true} path="/" component={Dashboard} />
        <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies} exact={true} path="/dashboard" component={Dashboard} />
        <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies} exact={true} path="/subscribers" component={Subscribers} />
        <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies} exact={true} path="/campaigns" component={Campaigns} />
        <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies} exact={true} path="/orders" component={Orders} />
        <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies} exact={true} path="/embed" component={Embed} />
        <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies} exact={true} path="/payments" component={Payments} />
        <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies} path="/payments/connect" component={Connect} />
        <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies} path="/campaigns/create" component={Create} />
        <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies} path="/campaigns/:id" component={CampaignView} />
        <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies} path="/orders/:id" component={OrdersView} />
        <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies} path="/subscribers/:id" component={SubscriberView} />
        <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies} path="/stripe/reauth" component={StripeReauth} />
        <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies} path="/stripe/callback" component={StripeCallback} />
        <Elements stripe={stripePromise}>

          {location === '/payments/billing' ? <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies} exact={true} path="/payments/billing" component={Billing} />: null
          }

          {location === '/wallet' ||location === '/dashboard'||  location === '/' || location ==='/campaigns' || location ==='/campaigns/create' || location === '/embed' || location === '/orders' || location === '/subscribers' ? <ProtectedRoute apiUrl={apiUrl} Cookies={AppCookies}  path="/wallet" component={Wallets} />:null
          }

        </Elements>
      </Switch>

    </BrowserRouter>
  </>
), document.getElementById('root'));