import React from 'react'
import { Helmet } from 'react-helmet'
// import 'thankyou_html/css/main.css'
export const Thankyou = () => {

    return (<div>
   
        <Helmet>
        
          <meta charset="utf-8"/>
          <meta http-equiv="x-ua-compatible" content="ie=edge"/>
          <title>Thank you page</title>
          <meta name="description" content=""/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <link rel="stylesheet" href="/thankyou_html/css/main.css"/>
       
        </Helmet>
        
        
        
        <body>
         
          <section class="thankyou-wrapper">
            <div class="ball-icon"></div>
            <div class="auto-container">
              <div class="thankyou-text">
                <h5>Mail Sent Successfully<i class="exclamation-icon"></i></h5>
                <p>Please Check your Mail.Thank You </p>
                <p>Now You Can Close This Tab. </p>
              </div>
              {/* <a  class="green-btn" onclick={()=>{window.close()}}>Close</a> */}
              <div class="thankyou-img">
                <img src="/thankyou_html/images/thankyou-img.png" alt="" />
              </div>
           
            </div>
          </section>
          
        </body>
        
        
            </div>
          )
}

