import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import axios from 'axios';

const useQuery = () => new URLSearchParams(useLocation().search);

export const StripeCallback = (props) => {
    const query = useQuery();
    const [callbackComplete, setCallbackComplete] = useState(false);
    const [authFailed, setAuthFailed] = useState(false);
    useEffect(() => {
        const handleCallback = async () => {
            const state = query.get('state');
            const authState = props.Cookies.get('STRIPE_AUTH_STATE');
           console.log("on callBack state ===",state ," authState " ,authState)
            if (!state || !authState || state !== authState) return setAuthFailed(true);

            const url = `${props.apiUrl}/stripe/callback`;
            await axios.get(url);

            setCallbackComplete(true);
        }

        handleCallback();
    }, []);

    if (authFailed) return <Redirect to={'/error'} />;
    else if (callbackComplete) return <Redirect to={'/payments/connect'} />;
    else return <></>;

}
