import React, { useEffect } from 'react';

export const Logout = (props) => {
    useEffect(() => {
        props.Cookies.remove('token');
        props.Cookies.remove('resetauth')
        window.location.href = '/';
    }, []);

    return <div></div>;
}