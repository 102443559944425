import React, { useEffect, useState } from 'react';
import { Alert, Button, PageHeader } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

const copyCode = (text) => {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
        console.error('Async: Could not copy text: ', err);
    });
};



const getSnippet = (subdomain) => {
    return `<iframe style="width: 100%; border: none; height: 1200px; overflow: hidden;" src="https://${subdomain}.textanoffer.com" scrolling="no"></iframe>`;
}

export const Embed = (props) => {
    const [embedScript, setEmbedScript] = useState("");
    const [balance, setBalance] = useState(0);
    const [isShowWallet , setIsShowWallet] = useState(false);
    const [mid,setMid] = useState();
    useEffect(() => {
        const getEmbedSnippet = async () => {
            const response = await axios.get(`${props.apiUrl}/merchant/embed`);
            const subdomain = response.data.subdomain;
            const url = `${props.apiUrl}/merchant/landing/${subdomain}`;
            const landingresponse = await axios.get(url);

            setMid(landingresponse.data.id)
            setEmbedScript(getSnippet(subdomain));
        }
        getEmbedSnippet();
        fetchTransactionData();
    }, []);

    const fetchTransactionData = async () => {
        const url = `${props.apiUrl}/wallet/transaction`;
        const response = await axios.get(url);
        const data = response.data;
      if(data.length > 0){
            setBalance(data[0].amount)
            setIsShowWallet(true)
    
        }
    }

    const copyCodeForGeneralInfo = () => {
        const text = `<iframe style="width: 100%; border: none; height: 1200px; overflow: hidden;" src="https://embed.textanoffer.com/?mid=${mid}" scrolling="no"></iframe>`;
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
            console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    };

    return (
        <>
        { isShowWallet &&  balance === 0 ?  <Alert
                action={
                    <Button type={"link"}>
                        <Link to={'/wallet'}>Recharge Now</Link>
                    </Button>
                }
                message={ `Your Wallet is Runing With Insufficient Balance $ ${balance.toFixed(2)} .Please Recharge Now !`}
                type={ 'info'}
            /> : isShowWallet &&  balance < 5  ?  <Alert
            action={
                <Button type={"link"}>
                    <Link to={'/wallet'}>Recharge Now</Link>
                </Button>
            }
            message={ `Your Wallet is Runing With Insufficient Balance $ ${balance.toFixed(2)} .`}
            type={ 'warning'}
        />  : null
          
          }
            <PageHeader title={"Embed"} subTitle={"Click the button to copy the script."} />
            <Alert
                action={
                    <Button onClick={() => copyCode(embedScript)} type={"link"}>
                        Copy your embed code
                    </Button>
                }
                message={"You can paste this HTML snippet anywhere on your site where you want the form to display."}
                type={"warning"}
            />
            <br/>
            <Alert
                action={
                    <Button onClick={() => copyCodeForGeneralInfo()} type={"link"}>
                        Copy your embed code
                    </Button>
                }
                message={"You can paste this HTML snippet anywhere on your site where you want the form to display for gathering just name and phone number."}
                type={"warning"}
            />
        </>
    )
}
