import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

import { Card, Col, PageHeader, Row, DatePicker, Alert, Button } from 'antd';

import moment from 'moment';

import { DashboardChart } from '../components/DashboardChart';
import axios from 'axios';

const { RangePicker } = DatePicker;

function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date);
    newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return newDate;
}

const initializedStartDate = new Date();
initializedStartDate.setDate(initializedStartDate.getDate() - 7);
const initializedEndDate = new Date();
initializedEndDate.setDate(initializedEndDate.getDate() + 1);

export const Dashboard = (props) => {
    const [bankConnected, setBankConnected] = useState(false);
    const [orders, setOrders] = useState([]);
    const [subscribers, setSubscribers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [startDate, setStartDate] = useState(initializedStartDate);
    const [endDate, setEndDate] = useState(initializedEndDate);
    const [loading, setLoading] = useState(true);
    const [balance, setBalance] = useState(0);
    const [isShowWallet, setIsShowWallet] = useState(false);
    useEffect(() => {
        const fetchDashboardData = async () => {
            const url = `${props.apiUrl}/merchant/dashboard/`;
            const response = await axios.get(url);
            const {
                orders,
                subscribers,
                messages,
                bankConnected
            } = response.data;

            setOrders(orders);
            setSubscribers(subscribers);
            setMessages(messages);
            setBankConnected(bankConnected);
            setLoading(false);
        }
        const fetchTransactionData = async () => {
            const url = `${props.apiUrl}/wallet/transaction`;
            const response = await axios.get(url);
            const data = response.data;
            if (data.length > 0) {
                setBalance(data[0].amount)
                setIsShowWallet(true)

            }
        }

        fetchDashboardData();
        fetchTransactionData();
    }, []);

    const display = {
        orders: orders.filter(order => {
            const orderDate = convertUTCDateToLocalDate(new Date(order.date));
            return orderDate <= endDate && orderDate >= startDate
        }),
        subscribers: subscribers.filter(subscriber => {
            const subscribersDate = convertUTCDateToLocalDate(new Date(subscriber.createdAt));
            return subscribersDate <= endDate && subscribersDate >= startDate
        }),
        messages: messages.filter(message => {
            const messagesDate = convertUTCDateToLocalDate(new Date(message.createdAt));
            return messagesDate <= endDate && messagesDate >= startDate;
        })
    };

    const totals = {
        revenue: display.orders.reduce((prev, curr) => prev + (parseFloat(curr.total)), 0),
        subscribers: display.subscribers.length,
        messages: display.messages.length
    };

    return (
        !loading && <>
            <Helmet>
                <title>Dashboard - TextAnOffer</title>
            </Helmet>
            {isShowWallet && balance === 0 ? <Alert
                action={
                    <Button type={"link"}>
                        <Link to={'/wallet'}>Recharge Now</Link>
                    </Button>
                }
                message={`Your Wallet is Runing With Insufficient Balance $ ${balance.toFixed(2)} .Please Recharge Now !`}
                type={'error'}
            /> : isShowWallet && balance < 5 ? <Alert
                action={
                    <Button type={"link"}>
                        <Link to={'/wallet'}>Recharge Now</Link>
                    </Button>
                }
                message={`Your Wallet is Runing With Insufficient Balance $ ${balance.toFixed(2)} .`}
                type={'warning'}
            /> :
                isShowWallet && balance > 5 ? <Alert
                    action={
                        <Button type={"link"}>
                            <Link to={'/wallet'}>Recharge Now</Link>
                        </Button>
                    }
                    message={`Your Wallet Balance $ ${balance.toFixed(2)} .`}
                    type={'success'}
                /> :
                    null

            }

            <PageHeader
                title={"Dashboard"}
                subTitle={`Here are your stats for today, ${moment().format("MMM Do, YYYY")}`}
                extra={[
                    <RangePicker
                        defaultValue={[moment(startDate), moment(endDate)]}
                        onChange={(values) => {
                            const [start, end] = values;
                            setStartDate(new Date(start));
                            setEndDate(new Date(end));
                        }}
                    />
                ]}
            />
            {!bankConnected && <Alert
                action={
                    <Button type={"link"}>
                        <Link to={'/payments/connect'}>Connect bank account</Link>
                    </Button>
                }
                message={"You need to connect your bank account to begin receiving payments."}
                type={"warning"}
            />}
            <br />
            <Row>
                <Col lg={8} md={8} xs={24} sm={24}>
                    <Card title="Total Revenue" style={{ marginTop: 8, marginRight: 30 }}>
                        <h1>${totals.revenue.toFixed(2)}</h1>
                    </Card>
                </Col>
                <Col lg={8} md={8} xs={24} sm={24}>
                    <Card title="New Subscribers" style={{ marginTop: 8, marginRight: 30, marginLeft: 30 }}>
                        <h1>{totals.subscribers}</h1>
                    </Card>
                </Col>
                <Col lg={8} md={8} xs={24} sm={24}>
                    <Card title="Messages Sent" style={{ marginTop: 8, marginLeft: 30 }}>
                        <h1>{totals.messages}</h1>
                    </Card>
                </Col>
            </Row>
            <Row style={{ marginTop: 72 }}>
                <Col span={24}>
                    <DashboardChart
                        startDate={startDate}
                        endDate={endDate}
                        orders={display.orders}
                        subscribers={display.subscribers}
                        messages={display.messages}
                    />
                </Col>
            </Row>
        </>
    )
}
