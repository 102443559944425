import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {
    Layout,
    Menu,
    Form,
    Input,
    Button,
    Alert,
    Row,
    Col
} from 'antd';

import { AppFooter } from '../components/Footer';

const { Content, Header } = Layout;
const fields = ['Email', 'Password', 'First_Name', 'Last_Name', 'Phone_Number', 'name'];

export const Register = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const contentStyle = {
        height: "518px",
        color: "#fff",
        lineHeight: "300px",
        textAlign: "center",
        background: "#364d79",
      };
    
    const onFinish = async (values) => {
        setLoading(true);
        const { Email, Password, First_Name, Last_Name, Phone_Number, name } = values;
        const registerRoute = `${props.apiUrl}/merchant/create/`;
        try {
            const response = await axios.post(registerRoute, {
                email:Email,
                insecurePassword: Password,
                firstName:First_Name,
                lastName:Last_Name,
                phoneNumber:Phone_Number,
                name
            });

            const token = response.data.token;
            props.Cookies.set('token', token, {
                sameSite: 'strict',
                secure: true
            });

            window.location.href = '/';
        } catch (e) {
            setError("Email address already in use, please use a new email address.");
            setLoading(false);
        }
    };

    const onFinishFailed = async (e) => console.log('Failed:', e);

    const displayFields = (fields) => fields.map(field => {
        const required = field !== 'addressTwo';
        const message = `Please input your ${field}!`;
        const rules = [{ required, message }];
        return (
            <Form.Item label={field !== "name" ? field.split('_').join(" ") : "Organization Name"} name={field} rules={rules}>
                {field === 'Password' ? <Input.Password /> : <Input />}
            </Form.Item>
        )
    });

    return (
        <Layout className="layout">
            <Header>
                <div className="logo">
                    <img src={"/logo.svg"} alt={"logo"} width={200} style={{ marginTop: '-18px' }} />
                </div>
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[0]}>

                </Menu>
            </Header>
            <Content style={{ padding: '0 50px' }}>
                <div style={{ margin: '30px 0' }}></div>
                <div className="site-layout-content">
                    <Row>
                        <Col lg={6} md={8} xs={24} sm={24}>
                            <h1>Register</h1>
                            <Form
                                 style={{ marginBottom: '10px' }}
                                name="basic"
                                initialValues={{
                                    remember: true,
                                }}
                                layout="vertical"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                {displayFields(fields)}
                                {error && <Alert type={"error"} message={error} />}
                                <br />
                                <Form.Item>
                                    <Button loading={loading} type="primary" htmlType="submit">Register</Button>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col lg={14} md={10} xs={20} sm={20} offset={2}>
                        <div>
                 
                  {/* <img src={"/signup.jpg"} alt={"Logo"} style={contentStyle} /> */}
                </div>
                        </Col>
                    </Row>
                </div>
            </Content>
            <AppFooter />
        </Layout>
    )
}