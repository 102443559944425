import React, { useEffect, useState } from "react";

import { Redirect, Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import { Alert } from 'antd';
import Box from '@mui/material/Box';
import {
  Form,
  DatePicker,
  Button,
  Col,
  Input,
  Row,
  Card,
  Modal,
  notification,
  Space,
  Radio,
  Typography
} from "antd";

import axios from "axios";
import moment from "moment";

import { PictureOutlined } from "@ant-design/icons";
const { Title, Paragraph ,Text} = Typography;
function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

export const Create = (props) => {
  const [isShow, setIsShow] = useState(false);
  const [message, setMessage] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [file, setFile] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verifyingNumber, setVerifyingNumber] = useState(false);
  const [numberVerified, setNumberVerified] = useState(false);
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [verificationCode, setVerificationCode] = useState(false);
  const [textMessagebg, setTextMessagebg] = useState("");
  const [textOfferbg, setTextOfferbg] = useState("rgb(234 69 34 / 83%)");

  const uploadRef = React.useRef(null);
  const { Meta } = Card;
  const [approxChargePerCampaigns, setApproxChargePerCampaigns] = useState(0);
  const [balance, setBalance] = useState(0);
  const [isvalid, setIsvalid] = useState(false);
  const [value, setValue] = useState(1);
  const [formShow, setFormShow] = useState(false);
  const [isTrial, setIsTrial] = useState(true);
  // const [balance, setBalance] = useState(0);
  const [isShowWallet, setIsShowWallet] = useState(false);
  useEffect(() => {
    const fetchSubscribers = async () => {
      const perTextMessageCharge = 0.0079;
      const perPictureMessageCharge = 0.0200;
      const url = `${props.apiUrl}/subscriber/list/`;
      const response = await axios.get(url);
      const totalSubscriber = response.data.subscribers.length
      const TotalApporxCharge = (perTextMessageCharge + perPictureMessageCharge) * totalSubscriber
      setApproxChargePerCampaigns(TotalApporxCharge)

      // featch transaction 
      const urlTransaction = `${props.apiUrl}/wallet/transaction`;
      const responseTransaction = await axios.get(urlTransaction);
      const data = responseTransaction.data;
      if (data.length > 0) {
        setIsTrial(false);
        setBalance(data[0].amount)
        // setIsvalid(data[0].amount < TotalApporxCharge)
      }

    }
    fetchSubscribers();
    fetchTransactionData();
  }, [])

  const fetchTransactionData = async () => {
    const url = `${props.apiUrl}/wallet/transaction`;
    const response = await axios.get(url);
    const data = response.data;
    console.log("data", data.length);
    if (data.length > 0) {
      setBalance(data[0].amount)
      setIsShowWallet(true)

    }
  }
  const onFinish = (values) => {
    const sendForm = async () => {
      const { title, message, price } = values;
      const date = new Date(values.date);


      const actualPrice = price ? price : 0

      const url = `${props.apiUrl}/campaign/create/`;

      const formData = new FormData();

      formData.append("title", title);
      formData.append("message", message);
      formData.append("file", file);
      formData.append("price", actualPrice);
      formData.append("date", date);
      await axios.post(url, formData);
    };

    setSubmittingForm(true);
    sendForm()
      .then(() => setRedirect(true))
      .error((e) => alert(e));
  };

  const onFinishFailed = () => { };

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const file = event.target.files[0];
    const image = window.URL.createObjectURL(file);

    setFile(file);
    setImageUrl(image);
  };

  const sendPreview = async () => {
    const url = `${props.apiUrl}/campaign/preview/`;

    await axios.post(url, { phoneNumber, message });

    setShowPreviewModal(false);
    notification.open({
      message: "Message Delivered",
      description: `Your preview message was sent to ${phoneNumber}`,
    });
  };

  const verifyNumber = async () => {
    if (phoneNumber) {
      setVerifyingNumber(true);
      try {
        const url = `${props.apiUrl}/campaign/verify?phoneNumber=${phoneNumber}`;
        await axios.post(url);

        setShowVerificationCode(true);
      } catch (e) {
        alert("Invalid phone number");
        setVerifyingNumber(false);
      }
    }
  };

  const confirmVerificationCode = async () => {
    const url = `${props.apiUrl}/campaign/verify/code/${verificationCode}`;
    const response = await axios.post(url);

    if (response.data.status === "verified") {
      setNumberVerified(true);
      setShowVerificationCode(false);
    } else {
      alert("Invalid verification code");
    }
  };
  const showTextAnOfferCampaign = () => {
    if(handleNext()){
      setIsShow(false)
      setFormShow(true)
    }
   
  };

  const showTextMessageCampaign = () => {
    if(handleNext()){
      setIsShow(true);
      setFormShow(true)
     }
   
  }
  const handleNext = () => {
    if (balance <= 0 || balance < approxChargePerCampaigns) {
      notification.info({ message: 'Please Recharge Your Wallet !' });
      return false
    }else{
      return true
    } 

     
  }
  return !redirect ? (
    <>

      <Space
        direction="vertical"
        size="large"
        style={{
          display: 'flex',
        }}
      >

        {isShowWallet && balance === 0 ? <Alert
          action={
            <Button type={"link"}>
              <Link to={'/wallet'}>Recharge Now</Link>
            </Button>
          }
          message={`Your Wallet is Runing With Insufficient Balance $ ${balance.toFixed(2)} .Please Recharge Now !`}
          type={'info'}
        /> : isShowWallet && balance < 5 ? <Alert
          action={
            <Button type={"link"}>
              <Link to={'/wallet'}>Recharge Now</Link>
            </Button>
          }
          message={`Your Wallet is Runing With Insufficient Balance $ ${balance.toFixed(2)} .`}
          type={'warning'}
        /> : null

        }


        {!formShow && <div >
          <h2 style={{ textAlign: 'center' }}>What kind of campaign would you like  to send ?</h2>
          <Row style={{ marginTop: 50, marginLeft: 240 }}>
            <Col span={12} style={{maxWidth:'42%'}} >
              <div className="site-card-border-less-wrapper" >
                <Box
                  onClick={() => { showTextAnOfferCampaign() }}
                  sx={{
                    width: 350,
                    height: 240,
                    backgroundColor: '#d9d9d9',
                    cursor: "pointer",
                    border: 'solid',
                    borderWidth: 1,
                    '&:hover': {
                      backgroundColor: '#d9d9d9',
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                >
                  <Typography>
                    <Title level={3} style={{ textAlign: 'center', marginTop: '0.5em' }}>Offer Campaign</Title>
                    <Paragraph style={{ textAlign: 'justify', marginRight: '3em', marginLeft: '3em',fontSize:'12px' }}>
                      Send an offer campaign to your subscribers that they can reply to create instant sales.   
                      This is grate place to lunch exclusive products to your biggest fans ,get quick sales by offering popular
                      selections and much more!
                    </Paragraph>
                  </Typography>
                </Box>
              </div>
            </Col>
            <Col span={12}>
              <div className="site-card-border-less-wrapper">
                <Box
                  onClick={() => { showTextMessageCampaign() }}
                  sx={{
                    width: 350,
                    height: 240,
                    backgroundColor: '#d9d9d9',
                    cursor: "pointer",
                    border: 'solid',
                    borderWidth: 1,
                    '&:hover': {
                      backgroundColor: '#d9d9d9',
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                >
                  <Typography>
                    <Title level={3} style={{ textAlign: 'center', marginTop: '0.5em' }}>SMS/MMS Campaign</Title>
                    <Paragraph style={{ textAlign: 'justify', marginRight: '3em', marginLeft: '3em',fontSize:'12px' }}>
                      Send an SMS/MMS campaign to your subscribers to send customers to your site.
                      This is grate place to tease new product, send customers to your site , drive traffic  to a specific landing page
                      and much more .*
                      </Paragraph>
                    <Paragraph strong style={{ textAlign: 'justify', marginRight: '3em', marginLeft: '3em',fontSize:'12px' }}>They can not create purchases via this type of campaign.
                     </Paragraph>
                  </Typography>
                </Box>
              </div></Col>
          </Row>
        </div>}

        {/* {!formShow && <div className="site-card-wrapper">
          <Row gutter={24} style={{ justifyContent: "space-between"}}>
            <Radio.Group size='large' onChange={onChange} value={value} style={{ marginTop:'60px' , marginLeft:'30%' }}>
              <Radio value={1}>Text An Offer Campaign</Radio>
        
              <Radio value={2}>Text Message Campaign</Radio>
            </Radio.Group>
          </Row>
          <Button
            style={{
              height: "34px",
              cursor: "pointer",
              marginTop: '100px',
              marginLeft:'43%'
            }}
            type="primary"
            onClick={()=>{handleNext()}}
            >
            Next
          </Button>
        </div>} */}


        {formShow && <div style={{ marginTop: '25px' }}>
          <Helmet>
            <title>Create New Campaign - TextAnOffer</title>
          </Helmet>
          <Button
            style={{
              height: "32px",
              cursor: "pointer",
              marginBottom: '12px',
              marginLeft: '94%'
            }}
            type="primary"
            onClick={() => { setFormShow(false) }}
          >
            Back
          </Button>
          <Row>

            <Col lg={8} md={12} sm={24} xs={24}>
              <h1>Create New Campaign {!isShow ? "TextAnOffer" : "TextMessage"}</h1>
              <p>This campaign will be sent to all of your subscribers.</p>
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please give your campaign a title!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Date"
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: "Please select a time for your campaign!",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: 195 }}
                    showTime={{
                      use12Hours: true,
                      format: "hh:mma",
                      minuteStep: 15,
                    }}
                    onChange={function onChange(value, dateString) {
                      console.log("Selected Time: ", value);
                      console.log("Formatted Selected Time: ", dateString);
                    }}
                    format={"MM-DD-YYYY h:ma"}
                    onOk={() => { }}
                    disabledDate={(current) =>
                      current &&
                      current < new Date(moment().subtract(1, "days").endOf("day"))
                    }
                    disabledSeconds={() => range(1, 60)}
                  />
                </Form.Item>
                {!isShow && <Form.Item
                  label="Price"
                  name="price"
                  type="number"
                  htmlType="number"
                  rules={[
                    {
                      required: true,
                      message: "Please input a price for your offer!",
                    },
                  ]}
                >
                  <Input addonBefore={"$"} />
                </Form.Item>}
                <Form.Item
                  label="Message"
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a message for your campaign!",
                    },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={1600}
                    autoSize={{ minRows: 4 }}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={() => {
                      uploadRef.current.click();
                    }}
                    icon={<PictureOutlined />}
                  >
                    Add Media
                  </Button>
                  <input
                    id="myInput"
                    type="file"
                    accept="image/*"
                    ref={uploadRef}
                    style={{ display: "none" }}
                    onChange={onChangeFile.bind(this)}
                  />
                  <p>Upload .jpg, .png, or .gif files only.</p>
                </Form.Item>
                <Form.Item>
                  <Button loading={submittingForm} type="primary" htmlType="submit">
                    Schedule Campaign
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col lg={14} md={10} sm={22} xs={22} offset={2}>
              <Card
                title={"Preview Message"}
                extra={
                  <Button
                    onClick={() => setShowPreviewModal(true)}
                    type={"link"}
                    disabled={!message}
                  >
                    Text it to me
                  </Button>
                }
              >
                {!imageUrl ? (
                  <></>
                ) : (
                  <img src={imageUrl} alt={"preview"} width={"50%"} />
                )}
                {!message ? (
                  <p>Add a message to preview.</p>
                ) : (
                  <div className="chat">
                    <div className="yours messages">
                      <div className="message last">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `${message.replace(/(?:\r\n|\r|\n)/g, "<br>")}`,
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
          <Modal
            title="Preview Message"
            visible={showPreviewModal}
            footer={null}
            onCancel={() => setShowPreviewModal(false)}
          >
            {!numberVerified && !showVerificationCode ? (
              <Form
                name="verifyNumber"
                layout="vertical"
                onFinish={verifyNumber}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Enter your phone number"
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone number.",
                    },
                  ]}
                >
                  <Input
                    prefix={"+1"}
                    style={{ width: "50%" }}
                    type={"tel"}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button loading={verifyingNumber} htmlType={"submit"}>
                    Verify Number
                  </Button>
                </Form.Item>
              </Form>
            ) : !numberVerified && showVerificationCode ? (
              <>
                <Form
                  name="verificationCode"
                  layout="vertical"
                  onFinish={confirmVerificationCode}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    name="code"
                    label={"Enter verification code"}
                    rules={[
                      {
                        required: true,
                        message: "Enter a verification code.",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => setVerificationCode(e.target.value)}
                      style={{ width: "50%" }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType={"submit"}>Enter Code</Button>
                  </Form.Item>
                </Form>
              </>
            ) : (
              <>
                <p>Sending preview to {phoneNumber}</p>
                <Button type={"primary"} disabled={!message} onClick={sendPreview}>
                  Send Preview
                </Button>
              </>
            )}
          </Modal>
        </div>}


      </Space>


    </>
  ) : (
    <Redirect to={"/campaigns"} />
  );


};
