import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import axios from "axios";

import {
  Col,
  PageHeader,
  Alert,
  Row,
  Button,
  List,
  Card,
  Tag,
  Skeleton,
  Space,
  Descriptions,
} from "antd";
import { Helmet } from "react-helmet";
import moment from "moment";

export const OrdersView = (props) => {
  const { Meta } = Card;
  const location = useLocation();
  const [, , id] = location.pathname.split("/");
  const [number, setNumber] = useState("");
  const [status, setStatus] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [quantitySold, setQuantitySold] = useState("");
  const [customer, setCustomer] = useState("");
  const [campaign, setCampaign] = useState("");
  const [date, setDate] = useState("");

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchOrder = async () => {
      const url = `${props.apiUrl}/order/view/${id}/`;
      const response = await axios.get(url);
      const subscriberId = response.data.order.subscriber;
      const subscriberurl = `${props.apiUrl}/subscriber/view/${subscriberId}/`;
      const res = await axios.get(subscriberurl);
      const subscribeInfo = res?.data.subscriber;
      console.log(subscribeInfo);
      // Shipping info
      setName(`${subscribeInfo.firstName} ${subscribeInfo.lastName}`);
      setAddress(
        subscribeInfo.addressOne
          ? subscribeInfo.addressOne
          : subscribeInfo.addressTwo
      );
      setCity(subscribeInfo.city);
      setState(subscribeInfo.state);
      setZip(subscribeInfo.zipCode);
      setPhone(subscribeInfo.phoneNumber);

      // order info
      setNumber(`#${response.data.order.number}`);
      setStatus(`${response.data.order.status}`);
      setTotalAmount(response.data.order.total.toFixed(2));
      setQuantitySold(response.data.order.quantity);
      setCustomer(
        `${response.data.order.subscriberInfo.firstName} ${response.data.order.subscriberInfo.lastName}`
      );
      setDate(response.data.order.createdAt);
      setCampaign(response.data.order.campaign);
      setLoading(false);
    };

    fetchOrder();
  }, []);

  const getOrderStatus = (status) => {
    let tag;
    switch (status) {
      case "processing":
        tag = <Tag>Processing</Tag>;
        break;
      case "processed":
        tag = <Tag color={"green"}>Processed</Tag>;
        break;
      case "cancelled":
        tag = <Tag color={"red"}>Cancelled</Tag>;
        break;
      default:
        tag = null;
        break;
    }

    return tag;
  };

  const processOrder = () => {
    const path = `/order/process/${id}/`;
    const requestUrl = `${props.apiUrl}${path}`;
    axios
      .patch(requestUrl)
      .then(() => {
        setStatus("processed");
      })
      .catch((err) => console.log(err));
  };

  const data = [
    {
      key: "Total amount",
      value: `$${totalAmount}`,
    },
    {
      key: "Quantity sold",
      value: quantitySold,
    },
    {
      key: "Customer",
      value: customer,
    },
    {
      key: "Campaign",
      value: campaign,
    },
    {
      key: "Date",
      value: `${date}`,
    },
  ];

  const Shippingdata = [
    {
      key: "Name",
      //   value: `$${totalAmount}`,
      value: name,
    },
    {
      key: "Address",
      //   value: quantitySold,
      value: address,
    },
    {
      key: "City",
      value: city,
    },
    {
      key: "State",
      value: state,
    },
    {
      key: "Zip Code",
      value: zip,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Order {number} - TextAnOffer</title>
      </Helmet>
      <PageHeader
        title={"Order Information"}
        subTitle={number}
        onBack={() => window.history.back()}
        tags={getOrderStatus(status)}
      />
      {!loading ? (
        <>
          {status === "processing" ? (
            [
              <Alert
                message={
                  "Time to fulfill this order! Once your customer has received their goods, mark it as processed."
                }
              />,
              <br />,
            ]
          ) : (
            <></>
          )}
    <div className="order-table">
            <Row>
              <Col span={24}>
                <Card>
                  <Descriptions
                    style={{ borderBottom: "1px solid #f0f0f0" }}
                    title="Order Details"
                    layout="vertical"
                  />

                  <List
                
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={item.key}
                          description={item.value}
                        />
                      </List.Item>
                    )}
                  />
                </Card>
                <br />
             </Col>

              <Col span={24}>
                <Card>
                  <Descriptions title="Shipping Details" layout="vertical">
                    <Descriptions.Item label="Name">{name}</Descriptions.Item>
                    <Descriptions.Item label="Telephone">
                      {phone}
                    </Descriptions.Item>
                    <Descriptions.Item label="Live">
                      {city}, {state}
                    </Descriptions.Item>
                    <Descriptions.Item label="Address" span={2}>
                      {address}, {city}, {state}, {zip}
                    </Descriptions.Item>
                  </Descriptions>
                    </Card>
                <br />
              </Col>
              <Space>
                <Button
                  disabled={status === "processed"}
                  onClick={processOrder}
                  type={"primary"}
                >
                  Mark as Processed
                </Button>
              </Space>
            </Row>
          </div>
        </>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

